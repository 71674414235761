// Mini-nav on various pages
.mini-nav {
	bottom: 0;
	width: 100%;
	position: fixed;
	padding: 1rem 0;
	background: #fff;
	z-index: $zindex-sticky;
	transform: translateY(100%);
	transition: $transition-base;
	padding-bottom: calc(1rem + env(safe-area-inset-bottom)); // Special, not supported by all browsers

	.btn {
		display: block;
	}

	&.visible {
		transform: translateY(0%);
		box-shadow: 0 -.5rem 1rem rgba($black, .15);
	}

	i.d-block {
		color: $navy-50;
	}
}

// Some overrides for when the mininav shows
body.has-mini-nav {
	$minNavPadding: 4.75rem;

	@include media-breakpoint-down(md) {
		#launcher {
			bottom: $minNavPadding !important;
			bottom: calc(#{$minNavPadding} + env(safe-area-inset-bottom)) !important; // Special, not supported by all browsers
		}
		.footer {
			padding-bottom: $minNavPadding;
			padding-bottom: calc(#{$minNavPadding} + env(safe-area-inset-bottom)); // Special, not supported by all browsers
		}
	}
}
body.has-mini-nav-lg {
	$minNavPadding: 6rem;

	@include media-breakpoint-down(md) {
		#launcher {
			bottom: $minNavPadding !important;
			bottom: calc(#{$minNavPadding} + env(safe-area-inset-bottom)) !important; // Special, not supported by all browsers
		}
		.footer {
			padding-bottom: $minNavPadding;
			padding-bottom: calc(#{$minNavPadding} + env(safe-area-inset-bottom)); // Special, not supported by all browsers
		}
	}
}