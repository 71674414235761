// autocomplete.scss
// https://www.npmjs.com/package/@tarekraafat/autocomplete.js
// https://tarekraafat.github.io/autoComplete.js/#/

// Styles only available as CSS so can't change ID...
// tarekraafat/autocomplete.js/dist/css/autoComplete.css

$searchbox-colour: $navy;
$searchbox-colour-10: $navy-10;
$searchbox-colour-25: $navy-25;
$searchbox-border-width: 2px;
$searchbox-padding-x: $btn-padding-x;
$searchbox-padding-y: $btn-padding-y;
$searchbox-container-z-index: 10;
$searchbox-dropdown-z-index: $zindex-dropdown + 1;

@if $stylesheet == 'giveasyoulive' or $stylesheet == 'blog-gayl' or $stylesheet == 'startpage' {
	$searchbox-colour: $blue;
	$searchbox-colour-10: $blue-10;
	$searchbox-colour-25: $blue-25;
}
@else if $stylesheet == 'donate' or $stylesheet == 'blog-donate' or $stylesheet == 'everyclick' {
	$searchbox-colour: $pink;
	$searchbox-colour-10: $pink-10;
	$searchbox-colour-25: $pink-25;
}
@else if $stylesheet == 'giftcards' {
	$searchbox-colour: $green;
	$searchbox-colour-10: $green-10;
	$searchbox-colour-25: $green-25;
}

.searchbox-container {
	overflow: visible;
	z-index: $searchbox-container-z-index;

	// Overflow visible (needed so that the dropdown is fully visible when in a .box) causes square corners 
	&.box > .box__content:first-child {
		border-radius: $border-radius-sm $border-radius-sm 0 0;
	}
}

.searchbox-form {
	margin: .5rem 0;
	position: relative;
	background: $white;
	transition: $transition-base;
	border: $searchbox-border-width solid $searchbox-colour;
	border-radius: $btn-border-radius; // For fully-rounded, use calc(1rem + $btn-padding-y);
	padding: $searchbox-padding-y $searchbox-padding-x;

	@include media-breakpoint-up(lg) {
		max-width: 35rem;
	}
	
	// Reset
	input, button {
		padding: 0;
		border: none;
		outline: none;
		background: none;		
	}
	input {
		width: 0; // To fix weird bug on iOS where it takes up 100%
		color: $text-colour;
	}
	button {
		color: $searchbox-colour;
		transition: $transition-base;

		// Add gap
		display: inline-grid;
		grid-auto-flow: column;
		grid-gap: .5rem;

		&:hover {
			opacity: 0.8;
		}
	}

	&:has(input:focus) {
		// Copied from Bootstrap's _form-control.scss
	    @if $enable-shadows {
			@include box-shadow($input-box-shadow, $input-focus-box-shadow);
		} 
		@else {
			box-shadow: $input-focus-box-shadow;
		}
	}
}

.searchbox-lg {
	&.searchbox-form {
		// border-radius: calc(1rem + $btn-padding-y-lg);
		padding: $btn-padding-y-lg $searchbox-padding-x;
	}

	input, button {
		@include font-size($input-font-size-lg);
	}
}

.searchbox-icon {
	margin-right: 1rem;
}

.searchbox-input {
	flex-grow: 1;
}

.searchbox-button {
	padding-left: 1rem;
}

.searchbox-form[aria-expanded=true] {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.searchbox-results {
	top: 100%;
	margin: 0;
	left: -$searchbox-border-width;
	padding: 0;
	overflow: hidden;
	position: absolute;
	width: calc(100% + (2 * $searchbox-border-width));
	box-shadow: $box-shadow;
	z-index: $searchbox-dropdown-z-index;
	border-radius: 0 0 $border-radius $border-radius;
	border: $searchbox-border-width solid $searchbox-colour; border-top: none;

	li {
		list-style: none;
		overflow: hidden;
		background: #fff;
		white-space: nowrap;
		text-overflow: ellipsis;
		transition: all 0.3s ease;

		&:nth-child(odd) {
			background: $searchbox-colour-10;
		}

		&[aria-selected=true] {
			cursor: pointer;
			background: $searchbox-colour-25;
		}

		> * { // This covers both 'a' and '.searchbox-recent'
			display: block;
			text-decoration: none;
			padding: $btn-padding-y-lg $searchbox-padding-x;
		}

		a:hover {
			background: $searchbox-colour-25;
			text-decoration: none;
		}

		.searchbox-recent {
			color: $grey-50;
			background: #fff;
		}
	}
}

.searchbox-clear {
	float: right;
}