//
// Charity page specific styles
//

img.charity-badge {
	width: auto;
	height: auto;
	max-height: 0.84em; // Aims to be the same as span.charity-badge
	border-radius: 0.25rem;
	vertical-align: baseline;
	border: 1px solid $navy-10;
}

span.charity-badge {
	--badge-padding-x: 0.4em;
	--badge-padding-y: 0.2em;
	--badge-font-size: 0.6em;
	--badge-border-radius: 0.25rem;
	--badge-color: #{$navy};

	background: $navy-10;
	letter-spacing: 0.05em;
	vertical-align: baseline !important;
	transform: translateY(-0.3em); // Magic number...
}

// Charity message speech bubble
.charity-message-bubble {
	color: $navy;
	padding: 1rem;
	text-align: left;
	position: relative;
	border-radius: 2rem;
	background-color: $marine-10;
	margin-bottom: 2rem !important;

	&:before {
		width: 0px;
		height: 0px;
		content: "";
		right: 5rem;
		bottom: -2rem;
		position: absolute;
		border-top: 1rem solid $marine-10;
		border-right: 1.5rem solid $marine-10;
		border-left: 1.5rem solid transparent;
		border-bottom: 1rem solid transparent;
	}
}