// Custom branding

// In the parent file, this is the last file so will override styles UNLESS that style already has an !important flag, in which case also use !important below
// For example, any .bg-{colour} or .text-{colour} classes use !important
// 
// Additional parent classes - in some cases these will trigger additional styles
// * custom-branding--dark-page
// * custom-branding--dark-content
// * custom-branding--dark-header
// * custom-branding--dark-footer

.custom-branding {
	// Global variables
	--box-bg: var(--contentBackground);
	--box-border-radius: var(--boxRoundness);
	--box-color: var(--contentTextColour);
	--box-stripe-bg: linear-gradient(rgba(var(--contentBackgroundStripeRGB), 0.05), rgba(var(--contentBackgroundStripeRGB), 0.05)), linear-gradient(var(--contentBackground), var(--contentBackground));
	--img-border-radius-sm: var(--imageRoundness);
	--img-border-radius: var(--imageRoundness);
	--navy-5-rgb: var(--pageBackgroundRGB);

	// Alerts

	.alert {
		--alert-border-radius: var(--boxRoundness);
	}

	// Backgrounds

	// Buttons

	.btn {
		--btn-border-radius: var(--buttonRoundness);
		--btn-active-bg: var(--btn-hover-bg);
		--btn-disabled-bg: var(--btn-bg);
		--btn-active-color: var(--btn-color);
		--btn-disabled-color: var(--btn-color);
		--btn-hover-color: var(--btn-color);
		// Below variables get over-ridden if an outline button
		--btn-active-border-color: var(--btn-border-color);
		--btn-disabled-border-color: var(--btn-border-color);
		--btn-hover-border-color: var(--btn-border-color);
	}

	.btn-default,
	.btn[class*="btn-outline-"]:not(.btn-outline-white) {
		--btn-active-border-color: var(--btn-hover-color);
		--btn-border-color: var(--btn-color);
		--btn-disabled-border-color: var(--btn-disabled-color);
		--btn-hover-border-color: var(--btn-hover-color);
		--btn-bg: #fff;
	}

	.btn-green {
		--btn-color: var(--primaryButtonText);
		--btn-border-color: var(--primaryButtonBackground);
		--btn-bg: var(--primaryButtonBackground);
		--btn-hover-bg: var(--primaryButtonHover);
	}

	.btn-navy {
		--btn-color: var(--secondaryButtonText);
		--btn-border-color: var(--secondaryButtonBackground);
		--btn-bg: var(--secondaryButtonBackground);
		--btn-hover-bg: var(--secondaryButtonHover);
	}

	.btn-outline-navy {
		--btn-color: var(--secondaryButtonBackground);
		--btn-border-color: var(--secondaryButtonText);
		--btn-bg: var(--secondaryButtonText);
		--btn-hover-bg: var(--secondaryButtonText);
	}

	.btn-outline-white {
		--btn-hover-color: #454545;
		--btn-active-color: #454545;
	}

	&.custom-branding--dark-header,
	&.custom-branding--dark-content {
		.btn-silver,
		.btn-default {
			--btn-bg: transparent;
			--btn-color: #{$silver};

			&:active,
			&:hover {
				color: var(--btn-color);
				background: linear-gradient(rgba($silver, 10%), rgba($silver, 10%)), var(--contentBackground) !important;
			}
		}
	}

	&.custom-branding--dark-footer {
		footer {
			--text-color-rgb: 255, 255, 255;
		}
	}

	// Carousels

	.owl-dot.active span, 
	.owl-dot:hover span {
		background: var(--secondaryButtonBackground);
	}

	// Forms and inputs

	input,
	select,
	textarea,
	.form-select,
	.input-group-text,
	div.form-control {
		border-radius: var(--buttonRoundness);
	}

	.input-group {
		:not(:last-child) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		:not(:first-child) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	.form-check-input {
		border-color: var(--secondaryButtonBackground);

		&:checked {
			border-color: var(--secondaryButtonBackground);
			background-color: var(--secondaryButtonBackground);
		}

		&:focus {
			box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width rgba(var(--secondaryButtonBackgroundRGB), $input-btn-focus-color-opacity);
		}
	}

	.btn-radio {
		// As the button will have a background colour, checked/hover/focus states will look odd
		.form-check-input {
			&:checked {
				border-color: transparent;
				background-color: transparent;
			}

			&:focus {
				box-shadow: none;
			}
		}
	}

	// Modals

	.modal {
		--modal-color: var(--contentTextColour);
		--modal-bg: var(--contentBackground);
		--modal-border-radius: var(--boxRoundness);
		--modal-inner-border-radius: calc(var(--boxRoundness) - 1px);
	}

	// Pagination

	.pagination {
		--link-color: var(--pageTextColour);
		--link-hover-color: var(--pageTextColour);
		--pagination-border-radius: var(--buttonRoundness);
		--pagination-active-color: var(--secondaryButtonText);
		--pagination-active-bg: var(--secondaryButtonBackground);
		--pagination-active-border-color: var(--secondaryButtonBackground);
		--pagination-disabled-color: rgba(var(--pageTextColourRGB), 0.5);
		--pagination-disabled-border-color: rgba(var(--pageTextColourRGB), 0.5);
	}

	&.custom-branding--dark-page,
	&.custom-branding--dark-content {
		.pagination {
			--link-color: #fff;
			--link-hover-color: #fff;
			--pagination-border-color: currentColor;
			--pagination-bg: transparent;
			--pagination-hover-bg: rgba(255, 255, 255, 0.2);
			--pagination-disabled-bg: transparent;
		}
	}

	ul.parsley-errors-list li {
		border-radius: var(--buttonRoundness);
	}
	
	// headerBackground

	.header {
		color: var(--headerTextColour);
		background-color: var(--headerBackground);
		--link-color: var(--headerTextColour);
		--link-hover-color: var(--headerTextColour);
		--text-color-rgb: var(--contentTextColourRGB);
	}

	// pageBackground

	&.bg-navy-5 .website-footer {
		--body-color: var(--pageTextColour);
		--link-color: var(--pageTextColour);
		--link-hover-color: var(--pageTextColour);

		.text-grey {
			--text-opacity: 1;
			--grey-rgb: var(--pageTextColourRGB);
		}

		.text-grey-30 {
			--text-opacity: 0.3;
			--grey-30-rgb: var(--pageTextColourRGB);
		}

		.text-navy-25 {
			--text-opacity: 0.25;
			--navy-25-rgb: var(--pageTextColourRGB);
		}
	}

	// footerBlockBackground

	.bg-navy {
		--navy-rgb: var(--footerBlockBackgroundRGB);
		--body-color: var(--footerBlockTextColour);
		color: var(--footerBlockTextColour) !important;
	}

	.bg-gradient-marine-teal {
		background-image: unset !important;
		background: var(--footerBlockBackground);
	}

	.box,
	.modal-content {
		--green: var(--contentGreenText);
		--green-rgb: var(--contentGreenTextRGB);
		--link-color: var(--contentTextColour);
		--link-hover-color: var(--contentTextColour);
		--border-color: rgba(var(--contentTextColourRGB), 0.1); // Used by leaderboards
		--text-color-rgb: var(--contentTextColourRGB);

		.bg-grey-5 {
			// Because we're laying with alpha transparency, use the var above rather than setting normal .bg-* variables
			background: var(--box-stripe-bg);
		}

		.text-grey-50:not(i) { // :not(i) is for leaderboard icons
			--text-opacity: 0.5;
			--grey-50-rgb: var(--contentTextColourRGB);
		}

		.text-grey-60 {
			--text-opacity: 0.6;
			--grey-60-rgb: var(--contentTextColourRGB);
		}

		.table {
			--table-color: var(--contentTextColour);
			--table-striped-color: var(--contentTextColour);
			--table-active-color: var(--contentTextColour);
			--table-hover-color: var(--contentTextColour);
		}

		.box__table thead th, 
		.box__table tfoot td {
			color: var(--secondaryButtonText) !important;
			background-color: var(--secondaryButtonBackground) !important;
		}
	}

	&.custom-branding--dark-content {
		#infobox__progress {
			--green-50: #{$green};
			--green-50-rgb: #{to-rgb($green)};
		}
	}

	.list-box div.bg-white {
		background-color: transparent !important;
	}

	// TomSelect dropdown tool

	.ts-control {
		color: $grey;
		border-radius: var(--buttonRoundness);
	}

	.ts-dropdown {
		border-radius:  0 0 var(--buttonRoundness) var(--buttonRoundness);
	}

	// Searchbox

	.searchbox-form {
		border-color: var(--primaryButtonBackground);

		button {
			color: var(--primaryButtonBackground) !important;
		}

		a {
			color: $grey !important;
		}
	}

	.searchbox-results {
		border-color: var(--primaryButtonBackground);

		li {
			&:nth-child(odd) {
				background: $grey-10;
			}

			a:hover,
			&[aria-selected=true] {
				background: $grey-20;
			}
		}
	}
}