//
// Main styles
//

// Search box (has inline search icon)
.search-box {
	width: 100%;
	background: #fff;
	border-radius: $border-radius;
	padding: $input-padding-y $input-padding-x;

	// Reset
	input, button {
		border: none;
		outline: none;
		background: none;
	}
	button {
		transition: $transition-base;

		&:hover {
			opacity: 0.8;
		}
	}
}