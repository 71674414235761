/*
 * Dashboard
 * This is for common stuff for the dashboard. For site-specific stuff, use a separate file. 
 */

// Dashboard banners

.dashboard-banner img {
	transition: opacity .2s ease-in-out;
}

.dashboard-banner a:hover img {
	opacity: 0.8 !important;
}

.dashboard-banner {
	.owl-stage-outer {
		border-radius: .5rem;
		background-color: #fff;
		box-shadow: $box-shadow;
	}
}

.dashboard-banner + .dashboard-banner,
.dashboard-banner + .box,
.dashboard-banner + .boxes,
.box + .dashboard-banner {
	margin-top: $grid-gutter-width;
}

// Displays a .badge at the bottom edge of a logo
.has-badge-overlay {
	display: block;
	position: relative;

	.badge {
		top: 100%;
		left: 50%;
		position: absolute;
		transform: translateX(-50%) translateY(-50%);
	}
}