@font-face {
	font-family:"gotham-black";
	src:
		// url("/fonts/gotham-black.woff2") format("woff2"),
		url("/fonts/gotham-black.woff") format("woff")
		// ,
		// url("/fonts/gotham-black.otf") format("opentype")
		;
	font-display: swap;
	font-style:normal;
	font-weight:300;
}