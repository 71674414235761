// 
// Colours
// 

$gaming-blue-dark: #0a0c28;
$gaming-blue-light: #1e3c8c;
$gaming-turquoise: #63bbb8;
$gaming-purple: #9461bb;

$golive-purple: #7140ca;
$golive-purple-light: #8e66d7;
$golive-orange: #e76d23;
$golive-pink: #c73c63;

// 
// Text
// 

@import "~Sass/common/fonts-gotham";

.text-gotham {
	// letter-spacing: -.125rem;
	// line-height: 1; Gotham Black line-spacing is terrible but removing it also not great
	font-family: gotham-black, 'Arial Black', sans-serif !important;
}

.text-golive-purple {
	color: $golive-purple  !important;
}

// 
// Backgrounds
// 

.bg-dark-blue-purple-radial {
	background-image: radial-gradient(circle at 40% 30%, $gaming-purple 20%, transparent 40%);
}

.bg-dark-blue-radial {
	background: $gaming-blue-dark;
	background-image: radial-gradient($gaming-blue-light 30%, $gaming-blue-dark);
}

.bg-turquoise {
	background: $gaming-turquoise;
}

.bg-gaming-circles {
	top: -11%;
	left: -20%;
	width: 140%;
	max-width: 140%;
	position: absolute;
}

.bg-gaming-ring {
	width: 100%;
	height: 100%;
	margin-top: 3.5rem;
	position: relative;

	img {
		top: 0;
		right: 0;
		max-width: unset;
		height: 120%;
		position: absolute;
	}
}

.bg-golive-purple-gradient {
	background-image: linear-gradient(to bottom right, $golive-purple-light, $golive-purple);
}

.bg-golive-orange-gradient {
	background-image: linear-gradient(to bottom right, $golive-orange, $golive-pink);
}

//
// Trippy angled boxes
//

$trippy: 12rem;
$trippy-border: clamp(1px, 30vw, $trippy);
$trippy-half-border: clamp(1px, 15vw, math.div($trippy, 2));
$trippy-border-neg: clamp(math.div($trippy, -1), -30vw, -1px);
$trippy-half-border-neg: clamp(math.div($trippy, -2), -15vw, -1px);

.box-trippy-lg__band {
	overflow: hidden;
	padding-top: $trippy-half-border;
	padding-bottom: $trippy-half-border;
}
.box-trippy-lg {
	border: solid;
	border-width: $trippy-half-border $trippy-border;
	border-image: url("/images/gaming/box-angled.svg") 25% 25% 20% 25% fill;
	border-image-outset: $trippy-half-border $trippy-border;

	> .box-trippy-lg__content {
		padding-top: 3rem;
		max-width: calc(100vw - $grid-gutter-width); // Hacky AF, the huuuge borders causes overflow on mobile
		margin: $trippy-half-border-neg $trippy-border-neg;
	}
}

//
// Trippy square boxes
//

$trippy: 3rem;
$trippy-border: clamp(1px, 20vw, $trippy);
$trippy-border-neg: clamp(math.div($trippy, -1), -20vw, -1px);

.box-trippy,
.box-trippy__content,
.box-trippy__container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.box-trippy__container {
	padding: 0 $trippy-border;
}
.box-trippy {
	border: solid;
	border-width: $trippy-border;
	border-image: url("/images/gaming/box-square.svg") 25% 25% 20% 25% fill;
	border-image-outset: $trippy-border;

	.box-trippy__content {
		max-width: calc(100vw - $grid-gutter-width); // Hacky AF, the huuuge borders causes overflow on mobile
		margin: $trippy-border-neg;
	}
}

// Other buttons

.btn-blue-pink {
	@include button-variant($gaming-blue-dark, $gaming-blue-dark, $pink);
}

.btn-pink-blue {
	@include button-variant($pink, $pink, $gaming-blue-dark);
}

// 
// Trippy images
//

.img-trippy {
	display: block; // As used on <a>
	position: relative;
}
.img-trippy__border {
	width: 100%;
	height: 100%;
	position: absolute;

	&:hover {
		transform: rotate(180deg);
	}
}
.img-trippy__content {
	width: 86%;
	margin: 0% 7%;
	border-radius: 50%;
	border: 1rem solid #fff;
}

.clip-trippy {
	clip-path: url(#clip-trippy);
	&:hover {
		clip-path: url(#clip-trippy-2);
	}
}
.owl-carousel a:hover img.clip-trippy {
	opacity: 1 !important; // Override Owl Carousel
}
